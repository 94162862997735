import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Page = ({ data }) => (
  <Layout>
    <SEO title="About Me" />
    <h1>About Dan</h1>
    <div className="o-media u-mb">
      <Img
        fluid={data.image.childImageSharp.fluid}
        alt="Dan Garland"
        className="b--thin b--solid b-light-grey o-media__img u-1-of-1-mobile u-1-of-4-tablet u-mb"
      />
      <div className="o-media__body u-1-of-1-mobile u-2-of-3-tablet u-pb">
        <p>
          I'm a web developer, teacher and tech founder, passionate about
          creativity and learning new things.
        </p>
        <p>
          In years of experience, I built apps for startups, agencies and SMEs .
          I taught web development for five years, at international schools and
          bootcamps.
        </p>
        <p>
          My favourite languages are HTML, CSS, Ruby and JavaScript, but I'm
          enjoying learning Go and Solidity right now.
        </p>
        <p>
          My proudest achievement in my career is when I founded a 12 week,
          live-in bootcamp that links junior web developers to jobs, called{" "}
          <a href="https://www.wegotcoders.com" class="link">
            We Got Coders
          </a>
          . I taught hundreds of students and directly employed over 75 of them,
          giving them their first opportunity as a web developer. We provided
          luxurious living at amazing venues around the UK, learning to code in
          style!
        </p>
        <p>
          What I love most about teaching, is that it provides us a chance to
          stop and think about all of the knowledge that I've accumulated, and
          pass it on to others. There's nothing better than the enthusiasm of
          learning something new, and so I'm lucky to have met so many people
          who have shared their learning experiences with me.
        </p>
        <p>
          When I'm not writing code, I enjoy making synth music and collecting
          synthesizers. As a proud dad, spare time is short, but I enjoy
          reading, cooking, playing chess and speaking French.
        </p>
      </div>
    </div>
    <section class="section heading">
      <h2>Interests &amp; Skills</h2>

      <div class="o-layout">
        <div class="o-layout__item u-1-of-1-mobile u-1-of-4-mobile">
          <Img
            fluid={data.bridge_house.childImageSharp.fluid}
            alt="Dan performing in London"
            title="Dan performing in London"
            class="screengrab u-mb"
          />
        </div>

        <div class="o-layout__item u-1-of-1-mobile u-3-of-4-mobile">
          <h3>Languages</h3>
          <p>English (Fluent), French (Intermediate).</p>

          <h3>Music</h3>
          <ul class="project-copy">
            <li>
              I play piano, keyboards and synthesizers, and <a href="https://soundcloud.com/dmgarland" class="link">enjoy composing my
              own music</a>. I am working with several musicians on original
              projects and have spent many months working with renowned record
              producers on song-writing and session playing.
            </li>
            <li>
              I have attained Grade 8 piano, after winning the Sheila Mossman
              Award for the highest mark in the country for Grade 6 piano, 2008.
            </li>
            <li>
              I played keyboards for Mercury-music prize nominees Sweet Billy
              Pilgrim, touring the UK and Europe to promote their much-acclaimed
              album <i>Crown and Treaty</i>.
            </li>
          </ul>

          <h3>Reading &amp; Writing:</h3>
          <p>
            I enjoy writing and have kept a number of blogs in the past. My
            writing has also been featured on the Brighton Argus website. I
            enjoy reading and particularly enjoy reading in French, especially
            translations from English authors.
          </p>
        </div>
      </div>
    </section>
  </Layout>
)
export const query = graphql`
  query {
    image: file(relativePath: { eq: "danbird.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    bridge_house: file(relativePath: { eq: "bridgehouse23.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`
export default Page
